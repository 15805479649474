import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderConfigService } from '@core/config/header-config.service';
import { UrlBuilder } from 'http-url-builder';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HorizonGenesysCallLogService {
  // Private variables
  private readonly baseUrl: UrlBuilder = new UrlBuilder(
    environment.apim.baseUrl
  ).addPath(environment.apim.apiSlugs.callLogApi);

  private formDataSubject = new BehaviorSubject<any>(null);
  private reportDataSubject = new BehaviorSubject<any>(null);

  // Public variables
  public formData$ = this.formDataSubject.asObservable();
  public reportData$ = this.reportDataSubject.asObservable();
  constructor(
    private http: HttpClient,
    private readonly headerConfigService: HeaderConfigService
  ) {}

  // #region CALL LOG API
  // ---------------------------------------------------------------------------------

  /**
   * Fetches call log details for a specific activity.
   * @param activityId The ID of the activity to fetch.
   * @returns An Observable with the call log view data.
   */
  public getCallLogView(activityId: number): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('callLog')
      .addPath(activityId.toString())
      .build();
    return this.http.get<any>(fullUrl);
  }

  /**
   * Retrieves a call log report based on provided parameters.
   * @param customerId The ID of the customer.
   * @param accountId The ID of the account.
   * @param callType The type of the call.
   * @param callSubtype The subtype of the call.
   * @param relationship The relationship type.
   * @returns An Observable with the call log report data.
   */
  public getCallLogReport(
    customerId: number,
    accountId: number,
    callType: string,
    callSubtype: string,
    relationship: string
  ): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('callLog')
      .addPath('details')
      .addQueryParam('AccountId', accountId.toString())
      .addQueryParam('CustomerId', customerId.toString())
      .addQueryParam('CallType', callType)
      .addQueryParam('CallSubtype', callSubtype)
      .addQueryParam('Relationship', relationship)
      .build();
    return this.http.get<any>(fullUrl);
  }

  /**
   * Fetches the call log template.
   * @returns An Observable with the template data.
   */
  public getTemplate(): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('callLog')
      .addPath('searchTemplate')
      .build();
    return this.http.get<any>(fullUrl);
  }

  /**
   * Searches for call log results based on provided parameters.
   * @param phoneNumber The phone number to search for.
   * @param name The name to search for.
   * @param accountRef The account ID to search for.
   * @param partyId Optional party ID to search for.
   * @returns An Observable with the search results.
   */
  public searchResults(
    phoneNumber?: string,
    name?: string,
    accountRef?: number,
    partyId?: number
  ): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('callLog')
      .addPath('customer')
      .addQueryParam('PhoneNumber', phoneNumber)
      .addQueryParam('Name', name)
      .addQueryParam('AccountRef', accountRef?.toString())
      .addQueryParam('PartyId', partyId?.toString())
      .build();
    return this.http.get<any>(fullUrl);
  }

  /**
   * Searches for a customer by telephone number.
   * @param phoneNumber The phone number to search for.
   * @returns An Observable with the search results.
   */
  public searchByTelephoneNumber(phoneNumber: string): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('callLog')
      .addPath('customer')
      .addQueryParam('PhoneNumber', phoneNumber)
      .build();
    return this.http.get<any>(fullUrl);
  }

  public getConversationDetails(conversationId: string): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('callconfiguration')
      .addPath('conversationdetail')
      .addQueryParam('conversationId', conversationId)
      .build();
    return this.http.get<any>(fullUrl);
  }

  public getCallClosureCode(
    pathVariables: {
      conversationId: string;
      participantId: string;
      communicationId: string;
    },
    data: any
  ): Observable<any> {
    const fullUrl: string = this.baseUrl
      .addPath('callconfiguration')
      .addPathVariable(pathVariables.conversationId)
      .addPath('participants')
      .addPathVariable(pathVariables.participantId)
      .addPath('communications')
      .addPathVariable(pathVariables.communicationId)
      .build();
    return this.http.post<any>(fullUrl, data);
  }

  // #endregion CALL LOG API

  //#region SET DATA
  // ---------------------------------------------------------------------------------

  /**
   * Sets the form data in the BehaviorSubject.
   * @param data The form data to set.
   */
  public setFormData(data: any) {
    this.formDataSubject.next(data);
  }

  /**
   * Sets the report data in the BehaviorSubject.
   * @param data The report data to set.
   */
  public setReportData(data: any) {
    this.reportDataSubject.next(data);
  }

  //#endregion SET DATA

  // #region SESSION AND LOCAL STORAGE WRAPPER FUNCTIONS
  // ---------------------------------------------------------------------------------

  /**
   * Deletes an item from session storage.
   * @param key The key of the item to delete.
   */
  public deleteLocalStorageData = (key: string) => {
    sessionStorage.removeItem(key);
  };

  /**
   * Retrieves and parses data from local storage.
   * @param key The key of the item to retrieve.
   * @returns The parsed data or the raw string if parsing fails.
   */
  public getLocalStorageData = (key: string) => {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      //Add some error handling logic
      console.error(e);
      return value;
    }
  };

  /**
   * Sets data in session storage, stringifying objects if necessary.
   * @param key The key under which to store the data.
   * @param value The value to store.
   */

  public setLocalStorageData = (key: string, value: string | object): void => {
    if (typeof value === 'object') value = JSON.stringify(value);
    localStorage.setItem(key, value);
  };
  //#endregion SESSION STORAGE WRAPPER FUNCTIONS

  //#region GENESYS SUBSTITUTE FUNCTIONS
  //------------------------------------------------------------------------------------------------------------
  public setGenesysSubstituteIcon(type: string): void {
    const toolbarOption = this.headerConfigService.toolbarOptions.find(
      (element) => element.action === 'genesys_substitute'
    );
    switch (type) {
      case 'default':
        toolbarOption.customSVG = `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill="white" d="M20 0 C20 6.66 20 13.33 20 20 13.33 20 6.66 20 0 20 0 13.33 0 6.66 0 0 6.66 0 13.33 0 20 0 z"></path>
          <path d="M1.625 2C1.625 1.79289 1.79289 1.625 2 1.625H18C18.2071 1.625 18.375 1.79289 18.375 2V18C18.375 18.2071 18.2071 18.375 18 18.375H2C1.79289 18.375 1.625 18.2071 1.625 18V2Z" fill="white" stroke="#002841" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M5 4.625H15C15.2071 4.625 15.375 4.79289 15.375 5V7C15.375 7.20711 15.2071 7.375 15 7.375H5C4.79289 7.375 4.625 7.20711 4.625 7V5C4.625 4.79289 4.79289 4.625 5 4.625Z" stroke="#002841" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M12 12.5C12 13.6046 11.1046 14.5 10 14.5C8.89543 14.5 8 13.6046 8 12.5C8 11.3954 8.89543 10.5 10 10.5C11.1046 10.5 12 11.3954 12 12.5Z" stroke="#002841" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M13 15.5L12 14.5" stroke="#002841" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        `;
        break;
      case 'selected':
        toolbarOption.customSVG = `
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill="white" d="M20 0 C20 6.66 20 13.33 20 20 13.33 20 6.66 20 0 20 0 13.33 0 6.66 0 0 6.66 0 13.33 0 20 0 z"></path>
            <path d="M1.625 2C1.625 1.79289 1.79289 1.625 2 1.625H18C18.2071 1.625 18.375 1.79289 18.375 2V18C18.375 18.2071 18.2071 18.375 18 18.375H2C1.79289 18.375 1.625 18.2071 1.625 18V2Z" fill="#002841" stroke="#002841" stroke-width="1.25"></path>
            <path d="M5 4.625H15C15.2071 4.625 15.375 4.79289 15.375 5V7C15.375 7.20711 15.2071 7.375 15 7.375H5C4.79289 7.375 4.625 7.20711 4.625 7V5C4.625 4.79289 4.79289 4.625 5 4.625Z" stroke="white" stroke-width="1.25"></path>
            <path d="M12 12.5C12 13.6046 11.1046 14.5 10 14.5C8.89543 14.5 8 13.6046 8 12.5C8 11.3954 8.89543 10.5 10 10.5C11.1046 10.5 12 11.3954 12 12.5Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M13 15.5L12 14.5" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
          `;
        break;
      default:
        break;
    }
  }
  //#endregion GENESYS SUBSTITUTE FUNCTIONS
}
